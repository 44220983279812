@keyframes card-rotating-anim-1 {
    from {
        transform: rotate(0deg) skew(60deg);
    }
    to {
        transform: rotate(360deg) skew(60deg);
    }
}

@keyframes card-rotating-anim-2 {
    from {
        transform: rotate(-180deg) skew(60deg);
    }
    to {
        transform: rotate(180deg) skew(60deg);
    }
}


.poker-card {
    position: relative;
}

.poker-card::before, .poker-card::after {
    transition: all 250ms linear 1000ms;
    pointer-events: none;
}

.poker-card-nothing::before, .poker-card-nothing::after {
    opacity: 0.0 !important;
    display: none;
}

.poker-card::before, .poker-card::after {
    position: absolute;
    content: "";
    inset: 50% auto auto 50%;
    width: 10000px;
    height: 10000px;


    background-color: yellow;

    transform: rotate(0deg) skew(60deg);
    transform-origin: top left;

    animation-name: card-rotating-anim-1;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.poker-card::after {
    animation-name: card-rotating-anim-2;
    z-index: -1;
}