@keyframes tada-anim {
    0% {
        opacity: 0.0;
    }
    10% {
        opacity: 1.0;
    }
    90% {
        opacity: 1.0;
    }
    100% {
        opacity: 0.0;
    }
}

.tada {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.0;
    z-index: 99999;

    animation-name: tada-anim;
    animation-duration: 8000ms;
    animation-iteration-count: 1;

    pointer-events: none;
}

@keyframes tada-paper-anim-1 {
    0% {
        transform: rotateY(0deg) rotateZ(-180deg);
        top: -30%;
        opacity: 0.0;
    }

    10% {
        transform: rotateY(0deg) rotateZ(-180deg);
        top: -30%;
        opacity: 1.0;
    }
    90% {
        transform: rotateY(360deg) rotateZ(180deg);
        top: 100%;
        opacity: 1.0;
    }
    100% {
        transform: rotateY(360deg) rotateZ(180deg);
        top: 100%;
        opacity: 0.0;
    }
}
@keyframes tada-paper-anim-2 {
    0% {
        transform: rotateY(-30deg) rotateZ(-90deg);
        top: -30%;
        opacity: 0.0;
    }

    10% {
        transform: rotateY(-30deg) rotateZ(-90deg);
        top: -30%;
        opacity: 1.0;
    }
    90% {
        transform: rotateY(30deg) rotateZ(90deg);
        top: 110%;
        opacity: 1.0;
    }
    100% {
        transform: rotateY(30deg) rotateZ(90deg);
        top: 110%;
        opacity: 0.0;
    }
}

.tada .paper-wrapper {
    position: fixed;
    inset: 0;
}

.tada .paper {
    position: fixed;
    opacity: 0.0;
    transform: rotateY(360deg) rotateZ(180deg);
    animation-iteration-count: 1;

}